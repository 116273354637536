//string : int
const forageMap = new Map([
    ["Wolf Meat", 30],
    ["Berries", 60],
    ["Iron Scraps", 20],
    ["Wolf Pelt", 30],
    ["Iron Ingot", 2]
])


export {forageMap}